<script lang="ts">
  import { orgArrowLeft, orgEditMetadata, orgExpand } from "@nrk/origo";
  import type { Image } from "bildebanken-model";
  import { getBildebankenHost, isEmbedded } from "config";
  import { isNtbSearchHit, isSearchHit, type SearchHit } from "services/searchHit";
  import SelectionService from "state/SelectionService";
  import {
    getCloseDetailsLink,
    pageState,
    preloadNeighbors,
    showDetailsForNextItem,
    showDetailsForPreviousItem,
    showEdit,
    type ImageSource,
  } from "state/page";
  import {
    createMetadataQuery,
    getMimirSearchHitIds,
    getMimirSearchHits,
  } from "state/queries/mimir";
  import { onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { getErrorMessage } from "utils/fns";

  import ImagePreview from "components/Common/ImagePreview.svelte";
  import PublishToKaleidoButton from "../Common/PublishToKaleidoButton.svelte";
  import DeleteImagesButton from "../DeleteImages/DeleteImagesButton.svelte";
  import PublishAndSelectButton from "../Plugin/PublishAndSelectButton.svelte";
  import FullscreenImage from "./FullscreenImage.svelte";
  import Metadata from "./Metadata.svelte";
  import RelatedImages from "./RelatedImages.svelte";
  import { useFeatureToggle } from "services/configCatService";

  export let itemId: string;
  export let itemSource: ImageSource = "Bildebanken";

  let fullscreen = false;

  $: searchHits = getMimirSearchHits();
  $: searchHitIds = getMimirSearchHitIds();
  $: searchHit = $searchHits.find((item) => item.id === itemId);

  $: query = createMetadataQuery(itemId, itemSource);
  $: image = $query.isSuccess ? $query.data : undefined;

  // preload Image while loading metadata
  const preload = new Image();
  preload.src = getPreviewUrl(searchHit);

  // Preload proxy for neighboring search hits, for faster loading when navigating with arrows
  $: preloadNeighbors(itemId, $searchHits);

  onMount(() => {
    document.addEventListener("keydown", arrowNavigate);
  });

  onDestroy(() => {
    document.removeEventListener("keydown", arrowNavigate);
  });

  function getPreviewUrl(image?: Image | SearchHit) {
    // high-res is too large and can't be cached because the URL is different each time (because of signing)
    // proxy is also way too large (because it's PNG), but smaller than highRes on average
    return (
      (isSearchHit(image) ? image.previewUrl : image?.proxy) ??
      new URL("/image-icon.svg", getBildebankenHost()).href
    );
  }

  // To avoid two-way binding on variable 'fullscreen'
  function setIsFullscreen(value: boolean) {
    fullscreen = value;
  }

  function arrowNavigate(event: KeyboardEvent & { target: Element }) {
    if (event.metaKey || event.altKey || event.ctrlKey) return;
    if (event.code !== "ArrowRight" && event.code !== "ArrowLeft") return;
    if (event.target.nodeName === "INPUT" || event.target.nodeName === "TEXTAREA") return;

    if (event.code === "ArrowRight") {
      showDetailsForNextItem($searchHitIds);
    } else {
      showDetailsForPreviousItem($searchHitIds);
    }
  }

  const isNoAccessError = (error: unknown) => {
    return (
      error &&
      typeof error === "object" &&
      "message" in error &&
      error.message === "No access to this page"
    );
  };

  const externalPatterns = [/\/images\/ntb\//];
  $: externalImage = externalPatterns.some((re) => re.test(window.location.pathname));
</script>

<div class="detail-container">
  {#if $query.isLoading}
    <div class="status" in:fade={{ duration: 200, delay: 300 }}>Laster metadata for ...</div>
  {:else if $query.isError}
    {#if isNoAccessError($query.error)}
      <div class="state-wrapper">
        <h3>Ingen tilgang</h3>
        <p>Bildet har begrenset synlighet.</p>
      </div>
    {:else}
      <div class="status">
        <p>Kunne ikke laste bildet med id <code>{itemId}</code>. Ugyldig lenke?</p>
        <div class="org-warning">{getErrorMessage($query.error)}</div>
      </div>
    {/if}
  {:else}
    <div class="left" class:fullscreen>
      {#if !fullscreen && image}
        <div class="image-wrapper">
          <!-- Use cached search hit if available,
                 as signed AWS URL to proxy can be
                 different in searchHit and image. -->
          <ImagePreview mode="detail" image={image || searchHit} />
        </div>
        <div class="action-container">
          <button
            class="org-button org-button--hidden-large org-button--icon-left"
            on:click={() => (fullscreen = !fullscreen)}
          >
            {@html orgExpand}
            Fullskjerm
          </button>
          {#if !isNtbSearchHit(image)}
            <button
              class="org-button org-button--hidden-large org-button--icon-left"
              on:click|preventDefault|stopPropagation={() => {
                SelectionService.send({ type: "SELECT_ITEMS", itemIds: [itemId] });
                showEdit([itemId]);
              }}
              disabled={externalImage}
            >
              {@html orgEditMetadata}
              Rediger metadata
            </button>
          {/if}
          <DeleteImagesButton selectedItems={searchHit ? [searchHit] : []} />
        </div>

        {#await useFeatureToggle("showRelatedImagesStandalone") then featureEnabled}
          {#if image.metadata?.jobId && featureEnabled}
            <RelatedImages jobId={image.metadata?.jobId} {image} />
          {/if}
        {/await}
      {/if}

      {#if image}
        <FullscreenImage
          src={getPreviewUrl(image)}
          srcHighRes={image.highRes ?? image.proxy}
          isFullscreen={fullscreen}
          {setIsFullscreen}
        />
      {/if}
    </div>

    <div class="right">
      <div class="button-container">
        <a href={getCloseDetailsLink($pageState)} class="org-button">
          {@html orgArrowLeft} Tilbake
        </a>
      </div>

      <h2 class="properties-title">Egenskaper</h2>

      {#if image}
        <Metadata {image} {externalImage} />
        <div class="publish-actions">
          {#key image?.id}
            {#if isEmbedded()}
              <PublishAndSelectButton item={image} primary={true} label="Bruk bilde" />
            {:else if !externalImage}
              <PublishToKaleidoButton item={image} />
            {/if}
          {/key}
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  .detail-container {
    width: 100%;
    max-width: 100%;
    position: relative;
    display: flex;
    height: calc(100vh - 50px);
  }

  .left {
    /* hack to not force right side out of screen when we have related images */
    min-width: 0;

    padding: var(--org-medium);
    flex: 1;
    display: flex;
    flex-basis: 75%;
    flex-direction: column;
  }

  .right {
    flex: 1;
    min-width: 500px;
    padding: var(--org-medium);
    background: var(--org-color-gray-700);
    overflow-y: scroll;
  }

  .image-wrapper {
    /* hack to keep portrait images inside screen */
    min-height: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    background-color: var(--org-color-black);
  }

  .status {
    padding: var(--org-medium);
  }

  .properties-title {
    /* Hack to overlap menu bar */
    margin-top: -1.5em;
  }

  .action-container {
    display: flex;
    justify-content: flex-end;
    gap: var(--org-xsmall);
    padding: var(--org-xsmall);
    flex-wrap: wrap;
  }

  .button-container {
    text-align: right;
  }

  .fullscreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 3;
    object-fit: contain;
  }

  .publish-actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--org-medium);
    align-items: center;
  }

  .state-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #888;
    width: 100%;
  }
</style>
