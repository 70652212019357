import { Mimir, type RightsMarker } from "bildebanken-model";

import { getDetailLink, getDetailLinkExternal } from "state/page";
import { getFallbackImageUrl, getThumbnailUrl } from "state/thumbnails";
import { kaleidoImageToRightsMarker } from "../components/Plugin/imageTypes";
import { getBildebankenGroupId, getKaleidoConfig } from "../config";
import type { Kaleido, Ntb } from "bildebanken-model";

type Visibility = "owner" | "selected-groups" | "everyone";

export type SearchHit = {
  id: string;
  state: "error" | "complete" | "new";
  thumbnailUrl?: string;
  previewUrl?: string;
  itemCreatedOn?: Date;
  mediaCreatedOn?: Date;
  metadata: {
    rightsMarker: RightsMarker;
    title?: string;
    description?: string;
    publicId?: string;
    copyrightOwners?: string[];
    usageTerms?: string;
    credit?: string;
    creditLine?: string;
    creators?: string[];
    keywords?: string[];
    sourceSystem?: string;
  };
  detailPageUrl?: string;
  visibleTo?: Visibility;
} & (
  | { type: "Mimir"; createdBy: string; originalFileName?: string }
  | { type: "Kaleido" }
  | { type: "Ntb" }
);

export type MimirSearchHit = SearchHit & { type: "Mimir" };
export type KaleidoSearchHit = SearchHit & { type: "Kaleido" };
export type NtbSearchHit = SearchHit & { type: "Ntb" };

export function isSearchHit(value?: object): value is SearchHit {
  return (
    !!value &&
    "type" in value &&
    (value.type === "Mimir" || value.type === "Kaleido" || value.type === "Ntb")
  );
}

export function isNtbSearchHit(value?: object): value is NtbSearchHit {
  return isSearchHit(value) && value.type === "Ntb";
}

export function getMimirVisibility(visibility?: Mimir.AssetVisibility): Visibility | undefined {
  if (!visibility) return;

  if (visibility.level === "owner") return "owner";
  if (visibility.level === "selected_groups") {
    if (visibility.groups?.includes(getBildebankenGroupId())) {
      return "everyone";
    }
    return "selected-groups";
  }

  // For legacy support
  if (visibility.level === "tenant") return "everyone";
}

export function parseOptionalDate(input?: string): Date | undefined {
  if (!input) {
    return;
  }

  const value = Date.parse(input);

  if (Number.isNaN(value)) {
    return;
  }

  return new Date(value);
}

export function mapToSearchHits<T, V extends SearchHit>(hits: T[], map: (v: T) => V | undefined) {
  return hits.reduce<V[]>((hits, hit) => {
    // filter and map in one go
    const mapped = map(hit);
    if (mapped) {
      hits.push(mapped);
    }
    return hits;
  }, []);
}

export function mimirResultToSearchHit(input: Mimir.SearchResultItem): MimirSearchHit | undefined {
  const inputMetadata = input.metadata.formData;

  if (input.itemState !== "complete" && input.itemState !== "error" && input.itemState !== "new") {
    console.warn("Skipping Mimir search result with unknown itemState", input.itemState, input);
    return;
  }

  return {
    id: input.id,
    state: input.itemState,
    thumbnailUrl: getThumbnailUrl(input),
    previewUrl: input.proxy || getFallbackImageUrl(),
    itemCreatedOn: parseOptionalDate(inputMetadata.createdOn),
    mediaCreatedOn: parseOptionalDate(inputMetadata.mediaCreatedOn),
    detailPageUrl: getDetailLink(input.id),
    metadata: {
      title: inputMetadata.title || input.originalFileName,
      rightsMarker: inputMetadata.rightsMarker || "Unknown",
      description: inputMetadata.description,
      publicId: inputMetadata.publicId,
      copyrightOwners: inputMetadata.copyrightOwners,
      usageTerms: inputMetadata.usageTerms,
      credit: input.metadata.formData.data?.rights?.credit || undefined,
      creditLine: inputMetadata.creditLine,
      creators: inputMetadata.creators,
    },
    type: "Mimir",
    visibleTo: getMimirVisibility(input.visibleTo),
    createdBy: input.createdBy,
    originalFileName: input.originalFileName,
  };
}

export function ntbImageToSearchHit(input: Ntb.ImageMetadata): NtbSearchHit {
  return {
    id: input.id,
    type: "Ntb",
    state: "complete",
    mediaCreatedOn: parseOptionalDate(input.dateCreated),
    itemCreatedOn: parseOptionalDate(input.dateArchived),
    thumbnailUrl: input.previews[256]?.url,
    previewUrl: input.previews[1024]?.url,
    detailPageUrl: getDetailLinkExternal(input.id, "Ntb"),
    metadata: {
      title: input.headline || undefined,
      description: input.description || undefined,
      rightsMarker: "Restricted",
      creators: input.creditLine ? [input.creditLine.split("/")[0].trim()] : [],
      copyrightOwners: input.creator ? [input.creator || "NTB"] : [],
      usageTerms: input.usageTerms || undefined,
      creditLine: input.creditLine || undefined,
      keywords: input.keywords,
      sourceSystem: "NTB",
    },
    visibleTo: "everyone",
  };
}

export function kaleidoImageToSearchHit(input: Kaleido.Metadata): KaleidoSearchHit {
  return {
    id: input.id,
    type: "Kaleido",
    state: "complete",
    mediaCreatedOn: parseOptionalDate(input.created),
    itemCreatedOn: parseOptionalDate(input.added),
    detailPageUrl: getDetailLinkExternal(input.id, "Kaleido"),
    thumbnailUrl:
      input.thumbnail && getKaleidoConfig()
        ? new URL(input.thumbnail, getKaleidoConfig()?.gfx).href
        : undefined,
    // TODO: need to calculate a derivative ID if we are to include this
    // previewUrl: ??
    // We need to define this URL first
    //detailPageUrl: getDetailLink(input.id),
    metadata: {
      title: input.title,
      description: input.description,
      rightsMarker: kaleidoImageToRightsMarker(input),
      publicId: input.id,
      creators: input.creators,
      keywords: input.keywords,
      usageTerms: input.usage,
      copyrightOwners: (input.rights && [input.rights]) || undefined,
      sourceSystem: input.origin?.includes("polopoly") ? "Polopoly" : "Kaleido",
    },
    visibleTo: "everyone",
  };
}

export type SuccessfulSearchResult<T extends SearchHit> = {
  total: number;
  hits: T[];
  from: number;
};
