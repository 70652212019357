<script lang="ts">
  import { orgGarbage } from "@nrk/origo";
  import { getCurrentUser } from "services/api";
  import type { MimirSearchHit } from "services/searchHit";
  import { returnToSearchPage } from "state/page";
  import { onMount } from "svelte";
  import DeleteImageDialog from "./DeleteImageDialog.svelte";

  export let selectedItems: MimirSearchHit[] = [];

  let dialogElement: HTMLElement;
  let showDialog = false;

  const currentUser = getCurrentUser();

  function onToggle(this: HTMLElement) {
    if (this !== dialogElement) {
      return;
    } else if (this.hidden) {
      returnToSearchPage();
    }

    showDialog = !dialogElement.hidden;
  }

  onMount(() => {
    dialogElement.addEventListener("dialog.toggle", onToggle);

    return () => {
      dialogElement.removeEventListener("dialog.toggle", onToggle);
    };
  });

  $: canDeleteSelection =
    currentUser &&
    selectedItems.some((image) => image.createdBy === currentUser.id && !image.metadata.publicId);
</script>

<button
  class="org-button org-button--hidden-large org-button--icon-left"
  data-for="delete-dialog"
  aria-disabled={!canDeleteSelection}
>
  {@html orgGarbage}
  Slett
</button>
<bb-dialog id="delete-dialog" hidden bind:this={dialogElement}>
  <!-- We hide the dialog with this boolean so we can use the Svelte lifecycle to start and stop machine -->
  {#if showDialog}
    <DeleteImageDialog {selectedItems} />
  {/if}
</bb-dialog>

<style>
  bb-dialog {
    width: 700px;
    max-width: 90vw;
  }
</style>
