import type { Image, Mimir } from "bildebanken-model";
import { getBildebankenHost } from "../config";
import { type MimirSearchHit } from "services/searchHit";

const fileThumbnails = new Map<string, string>();

export function getFallbackImageUrl(): string {
  return new URL("/image-icon.svg", getBildebankenHost()).toString();
}

/**
 * Get the thumbnail url for an image.
 *
 * Returns url to in memory images for newly uploaded images that don't have a thumbnail yet.
 */
export function getThumbnailUrl(
  image: Partial<Image | Mimir.SearchResultItem>,
): string | undefined {
  return (
    image.thumbnail ??
    (image.originalFileName && fileThumbnails.get(image.originalFileName)) ??
    (image.id && fileThumbnails.get(image.id)) ??
    undefined
  );
}

/**
 * Get the thumbnail url for an image.
 *
 * Returns url to in memory images for newly uploaded images that don't have a thumbnail yet.
 */
export function getThumbnailUrlFromMimirSearchHit(image: MimirSearchHit): string | undefined {
  return (
    image.thumbnailUrl ??
    (image.originalFileName && fileThumbnails.get(image.originalFileName)) ??
    (image.id && fileThumbnails.get(image.id)) ??
    undefined
  );
}

export function linkThumbnailFilenameAndId(filename: string, id: string): void {
  const value = fileThumbnails.get(filename);
  if (value) {
    fileThumbnails.set(id, value);
  }
}

/**
 * Extracts and caches a thumbnail for in memory files (when uploading)
 */
export function storeUploadThumbnail(file: File) {
  if (file instanceof Blob && file.size > 0) {
    return new Promise<void>((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result instanceof ArrayBuffer) {
          const url = URL.createObjectURL(file);

          if (url) {
            fileThumbnails.set(file.name, url);
          }
        }
        resolve();
      };

      // this is not important enough that we want to handle errors
      reader.onerror = () => resolve();
      reader.onabort = () => resolve();

      reader.readAsArrayBuffer(file.slice(0, 128 * 1024));
    });
  } else {
    return Promise.resolve();
  }
}
