import { getBildebankenHost } from "config";
import { format } from "date-fns";
import { createPath, deleteFolder, getFolderContent } from "services/folders/api";
import type { Folder } from "services/folders/types";
import { updateFolderParam, updateSearchFilter } from "state/page";
import { get, writable, type Writable } from "svelte/store";
import { getEnvironmentFromHostname } from "utils/fns";

const DayOfWeek = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};

const weekdays = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
];
const weekend = [DayOfWeek.Saturday, DayOfWeek.Sunday];
const everyday = [...weekdays, ...weekend];

const shows: Map<string, { days: number[]; hour: number }> = new Map([
  ["Nyhetsmorgen", { days: weekdays, hour: 6 }],
  ["Sendedesken", { days: everyday, hour: 9 }],
  ["Helgemorgen", { days: weekend, hour: 9 }],
  ["Tegnspråknytt", { days: weekdays, hour: 17 }],
  ["Dagsnytt 18", { days: weekdays, hour: 18 }],
  ["Dagsrevyen", { days: everyday, hour: 19 }],
  ["Urix", { days: [DayOfWeek.Wednesday], hour: 20 }],
  ["Dagsrevyen 21", { days: everyday, hour: 21 }],
  ["Debatten", { days: [DayOfWeek.Tuesday, DayOfWeek.Thursday], hour: 21 }],
  ["Kveldsnytt", { days: everyday, hour: 23 }],
  ["HK-publisering TEST", { days: everyday, hour: 23.5 }],
]);

export const isoDateRe = /\d{4}-([0][1-9]|1[0-2])-([0][1-9]|[1-2]\d|3[01])/;

export const rootFolderIds = {
  stage: {
    productions: "8eb66c9b-648d-46db-bf22-82476dbfeb2b",
    album: "46737744-6412-4db5-a835-d789c0cdcc4b",
  },
  prod: {
    productions: "ff4cd9ac-31de-47a4-a77f-470e0e143f03",
    album: "e626faee-7bfb-44e0-a6fd-41c137a92a04",
  },
};

const rootFolderId = ["prod", "beta"].includes(
  getEnvironmentFromHostname(getBildebankenHost().hostname),
)
  ? rootFolderIds.prod.productions
  : rootFolderIds.stage.productions;

export const sortDailyShows = (a: Folder, b: Folder) => {
  return (shows.get(a.name)?.hour || 1) - (shows.get(b.name)?.hour || 0);
};

export async function setFolderFilter(folderId: string): Promise<void> {
  updateSearchFilter("folderId", folderId);
  updateFolderParam(folderId);
}

export const dailyProductionsTemplate = (date: Date) => {
  return Array.from(shows.keys()).filter((title) => shows.get(title)?.days.includes(date.getDay()));
};

export type Path = { path: string; id?: string };

export type ProgressPromise<T> = { progress: Writable<number>; result: Promise<T> };

export function generateProductionFolders(
  dates: Date[],
  dateTemplate: (date: Date) => string[] = dailyProductionsTemplate,
): ProgressPromise<Path[]> {
  const progress = writable(0);

  const paths = dates
    .map((date) => {
      return { parent: format(date, "yyyy-MM-dd"), children: dateTemplate(date) };
    })
    .flatMap((folder) => folder.children.map((child) => `${folder.parent}/${child}`));

  async function createPaths() {
    const folders: Path[] = [];

    for (const path of paths) {
      const result = await createPath(path, rootFolderId);
      progress.set(get(progress) + 1.0 / paths.length);

      const folder = {
        path: result.map((structure) => Object.keys(structure)[0]).join("/"),
        id: result.map((structure) => Object.values(structure)[0]).pop(),
      };

      folders.push(folder);
    }

    return folders;
  }

  return { progress, result: createPaths() };
}

export function deleteProductionFolders(dates: Date[]): ProgressPromise<void> {
  const formattedDates = dates.map((date) => format(date, "yyyy-MM-dd"));

  const progress = writable(0);

  async function deleteFolders() {
    const parentFolders = (
      await getFolderContent(rootFolderId).then((folder) => folder.folders)
    ).filter((folder) => formattedDates.includes(folder.name));

    const subfolders = (
      await Promise.all(
        parentFolders.map(
          async (parent) => await getFolderContent(parent.id).then((parent) => parent.folders),
        ),
      )
    ).flat();

    const folderCount = parentFolders.length + subfolders.length;

    for (const subfolder of subfolders) {
      await deleteFolder(subfolder.id);
      progress.set(get(progress) + 1.0 / folderCount);
    }

    for (const folder of parentFolders) {
      await deleteFolder(folder.id);
      progress.set(get(progress) + 1.0 / folderCount);
    }

    return Promise.resolve();
  }

  return { progress, result: deleteFolders() };
}
