<script lang="ts">
  import { debounce, last } from "lodash-es";
  import { type SearchHit } from "services/searchHit";
  import { createPreloader } from "state/queries/mimir";
  import { onMount } from "svelte";
  import { preload } from "./preload";
  import { updateSearchFilter } from "state/page";
  import type { SearchParams } from "state/params";
  import { orgLocked } from "@nrk/origo";
  import RightsIndicator from "../Common/RightsIndicator.svelte";

  export let images: SearchHit[];
  export let highlightId: string | undefined = undefined;

  let imageElements = new Map<String, HTMLElement>();

  const scrollToHighlighted = () => {
    highlightId &&
      imageElements[highlightId]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
  };

  let imagesWrapperEl: HTMLElement | undefined;
  let wrapperWidth: number;
  let scrollWidth: number | undefined = undefined;
  let scrollPosition: number | undefined = undefined;
  $: scrollRight =
    (wrapperWidth !== undefined &&
      scrollWidth !== undefined &&
      scrollPosition !== undefined &&
      scrollWidth - (scrollPosition + (imagesWrapperEl?.offsetWidth || 0))) ||
    0;

  $: moreBefore = scrollPosition && scrollPosition > 0;
  $: moreAfter = scrollRight > 0;
  let spaceAvailable = true;

  let lastImageLoaded = false;
  $: lastImageEl = lastImageLoaded && last(images)?.id && imageElements[last(images)?.id!];

  const debouncedSpaceAvailable = debounce(() => {
    if (imagesWrapperEl && lastImageEl) {
      const remaining =
        imagesWrapperEl.getBoundingClientRect().right - lastImageEl.getBoundingClientRect().right;
      spaceAvailable = remaining > 140;
    }
  }, 500);
  $: {
    scrollWidth && debouncedSpaceAvailable();
  }

  const checkScrollPosition = () => {
    scrollWidth = imagesWrapperEl?.scrollWidth;
    scrollPosition = imagesWrapperEl?.scrollLeft;
  };

  $: {
    highlightId && scrollToHighlighted();
  }

  const onImageLoad = (searchHit: SearchHit) => {
    const lastImage = [last(images)?.id].includes(searchHit.id);
    if (lastImage) {
      lastImageLoaded = true;
      scrollToHighlighted();
      checkScrollPosition();
    }
  };

  let debouncedScrollToHighlight = debounce(() => {
    scrollToHighlighted();
  }, 750);

  const onResizeListener = () => {
    checkScrollPosition();
    debouncedScrollToHighlight();
  };

  onMount(() => {
    window.addEventListener("resize", onResizeListener);
    return () => window.removeEventListener("resize", onResizeListener);
  });

  export let context: SearchParams | undefined = undefined;

  const updateSearchContext = () => {
    if (context?.owner) {
      updateSearchFilter("owner", context.owner);
    }
  };
</script>

<div
  class="images fade"
  class:fadeStart={moreBefore}
  class:fadeEnd={moreAfter}
  bind:this={imagesWrapperEl}
  bind:clientWidth={wrapperWidth}
  on:scroll={() => checkScrollPosition()}
>
  {#each images as searchHit}
    <div style="position: relative">
      {#if searchHit.metadata.rightsMarker !== "Free"}
        <div class="usage-terms">
          <div class="rights-indicator">
            <RightsIndicator rightsMarker={searchHit.metadata.rightsMarker} />
          </div>
        </div>
      {/if}
      {#if searchHit.visibleTo == "owner"}
        <div class="visibility">
          {@html orgLocked}
        </div>
      {/if}
      <a
        draggable="false"
        href={searchHit.detailPageUrl}
        on:click={updateSearchContext}
        class:highlight={searchHit.id === highlightId}
        use:preload={createPreloader(searchHit)}
      >
        <img
          draggable="false"
          src={searchHit.thumbnailUrl}
          alt=""
          bind:this={imageElements[searchHit.id]}
          on:load={() => onImageLoad(searchHit)}
        />
      </a>
    </div>
  {/each}
  {#if (spaceAvailable && lastImageLoaded) || images.length === 0}
    <slot name="ifSpace" />
  {/if}
</div>

<style>
  .images {
    display: flex;
    overflow-x: scroll;
    gap: var(--org-small);
    height: 100px;
    max-height: 100%;
    border-radius: 5px;
    padding: 0px 0;
    position: relative;

    /* Hide scrollbar in Firefox */
    scrollbar-width: none;
  }

  .images::-webkit-scrollbar {
    display: none;
  }

  .fade {
    --fade-width: 75px;
    --fade-in-width: 0px;
    --fade-out-width: 0px;
    --fade-from: transparent;
    --fade-to: transparent;
    mask:
      linear-gradient(to left, black, var(--fade-from)) left / var(--fade-in-width) no-repeat,
      linear-gradient(black, black) no-repeat var(--fade-in-width) /
        calc(100% - var(--fade-in-width) - var(--fade-out-width)),
      linear-gradient(to right, black, var(--fade-to)) right / var(--fade-out-width) no-repeat;
    transition: mask 0.25s;
  }

  .fadeStart {
    --fade-in-width: var(--fade-width);
    transition: mask 0s;
  }

  .fadeEnd {
    --fade-out-width: var(--fade-width);
    transition: mask 0s;
  }

  .images img {
    max-height: 100%;
    min-width: 20px;
  }

  .images a {
    height: 100%;
    border: 3px transparent solid;
    border-radius: 2px;
  }

  .images a.highlight {
    border-color: var(--nrk-color-core-blue-600);
  }

  .usage-terms {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: var(--org-xsmall);
    top: 20%;
    background: var(--color-surface-primary);
    border-radius: var(--org-xsmall);
    overflow: hidden;
    font-size: 12px;
  }

  .rights-indicator {
    padding-inline: var(--org-xsmall);
    margin-top: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .visibility {
    position: absolute;
    bottom: 10%;
    left: var(--org-xsmall);
    background: var(--color-surface-secondary);
    border-radius: var(--org-xsmall);
    font-size: 10px;
    padding: 4px;
    display: flex;
    justify-content: center;
  }
</style>
