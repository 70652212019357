<svelte:options immutable />

<script lang="ts">
  import type { Image } from "bildebanken-model";
  import { isSearchHit, type SearchHit } from "services/searchHit";
  import { getFallbackImageUrl, getThumbnailUrl } from "state/thumbnails";
  import ImagePlaceholder from "./ImagePlaceholder.svelte";

  type ImageToPreview = Image | SearchHit;
  export let image: ImageToPreview | undefined;
  export let mode: "table" | "tile" | "detail";

  function getProxy(image?: ImageToPreview) {
    if (!image) return;
    if (isSearchHit(image)) return image.previewUrl || getFallbackImageUrl();
    return image.proxy ?? getFallbackImageUrl();
  }

  function getThumbnail(image?: ImageToPreview) {
    if (!image) return;
    if (isSearchHit(image)) return image.thumbnailUrl || getFallbackImageUrl();
    return getThumbnailUrl(image);
  }

  function hasProxy(image?: ImageToPreview) {
    if (!image) return;
    if (isSearchHit(image)) return !!image.thumbnailUrl;
    return !!image.proxy;
  }

  function hasThumbnail(image?: ImageToPreview) {
    if (!image) return;
    if (isSearchHit(image)) return !!image.thumbnailUrl;
    return !!getThumbnailUrl(image);
  }

  function getPreviewUrl(image?: ImageToPreview) {
    switch (mode) {
      case "table":
      case "tile":
        return getThumbnail(image);
      case "detail":
        return getProxy(image);
      default:
        break;
    }
  }

  function hasPreviewUrl(image?: ImageToPreview) {
    switch (mode) {
      case "table":
      case "tile":
        return hasThumbnail(image);
      case "detail":
        return hasProxy(image);
      default:
        break;
    }
  }

  function imageIsImageType(image: ImageToPreview | undefined): image is Image {
    return !(image && "formData" in image.metadata);
  }

  $: itemState =
    image && (("itemState" in image && image.itemState) || ("state" in image && image.state));
  $: error = itemState === "error";

  $: height =
    image && imageIsImageType(image) && image?.technicalMetadata?.formData.technical_image_height;
  $: width =
    image && imageIsImageType(image) && image?.technicalMetadata?.formData.technical_image_width;

  $: imageSizeStyle = height && width ? `--org_height: ${height}px; --org_width: ${width}px` : "";
</script>

{#if (itemState === "complete" || hasPreviewUrl(image)) && !error}
  <img style={imageSizeStyle} src={getPreviewUrl(image)} alt="" on:error={(e) => (error = true)} />
{:else if itemState === "new"}
  <ImagePlaceholder state="new" {mode} />
{:else}
  <ImagePlaceholder state="error" {mode} />
{/if}

<style>
  img {
    --org_width: 100%;
    --org_height: 100%;
    align-self: center;
    overflow: hidden;
    object-fit: contain;
    flex-grow: 1;
    margin: 0 auto;
    display: block;
    max-width: min(var(--org_width), 100%);
    max-height: min(var(--org_height), 100%);
  }
</style>
