export type NotificationType = "primary" | "success" | "error" | "warning" | "unsaved";

function getDuration(type: NotificationType) {
  switch (type) {
    case "primary":
    case "success":
      return 15000;
    default:
      return 5000;
  }
}

function assertiveNess(type: NotificationType) {
  switch (type) {
    case "error":
    case "warning":
    case "unsaved":
      return "assertive";
    default:
      return "polite";
  }
}

export function origoNotification(message: string, type: NotificationType) {
  document.body.insertAdjacentHTML(
    "beforebegin",
    `
    <bb-notification
        type="${type || ""}"
        duration="${getDuration(type)}"
        aria-live="${assertiveNess(type)}">
      ${message}
    </bb-notification>
    `,
  );
}
