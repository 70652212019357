<script lang="ts">
  import { type EditableMetadata, type Image } from "bildebanken-model";
  import { isEmpty } from "lodash-es";
  import { onMount } from "svelte";
  import type { StringValuedKeys } from "utils/typescript";
  import EditImageType from "./EditImageType.svelte";
  import EditMediaCreatedOn from "./EditMediaCreatedOn.svelte";
  import EditRights from "./EditRights.svelte";
  import EditVisibility from "./EditVisibility.svelte";
  import type { EditImageActor, EditImageState } from "./editImageMachine";
  import {
    fieldMissingForCurrentImage,
    getAllChanges,
    isRequiredField,
    updateField,
  } from "./editImageMachine";
  import { orgPlus, orgWarn } from "@nrk/origo";
  import { useFeatureToggle } from "services/configCatService";
  import { uuidRe } from "utils/fns";

  export let service: EditImageActor;
  export let state: EditImageState;
  export let image: Image;
  export let selectImageOnSave: boolean;
  export let editOnlyMode: boolean = false;
  export let highlightedFields: string[] | undefined;

  let chosenAltText: string | undefined;

  export let saveMetadata: () => void;

  function onTextFieldInput(name: StringValuedKeys<EditableMetadata>) {
    return function (this: HTMLInputElement) {
      updateField(service, name, this.value);
    };
  }

  $: titleMissing = isRequiredField(state, "title") && fieldMissingForCurrentImage(state, "title");

  function saveOnCmdEnter(event: KeyboardEvent) {
    if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      saveMetadata();
    }
  }

  onMount(() => {
    document.addEventListener("keydown", saveOnCmdEnter);
    return () => document.removeEventListener("keydown", saveOnCmdEnter);
  });

  function fieldsChanged() {
    return getAllChanges(state).filter((change) => !isEmpty(change.metadataDelta)).length > 0;
  }

  function beforeUnload(event) {
    if (fieldsChanged()) {
      // Setting the returnValue is what enables the 'Unsaved changes' prompt
      event.returnValue = "";
    }
  }

  // Strip out auto generated GUID title, such as when importing Polopoly images without title
  if (image.metadata.title?.substring(0, 36).match(uuidRe)) {
    image.metadata.title = "";
  }
</script>

<svelte:window on:beforeunload|preventDefault={beforeUnload} />
<div class="wrapper">
  <h2>Om bildet</h2>
  <label>
    Tittel
    <span class={!isRequiredField(state, "title") ? "hidden" : ""}>*</span>
    <input
      class="org-input"
      type="text"
      value={image.metadata.title || ""}
      placeholder="Objektets navn"
      on:input={onTextFieldInput("title")}
      required
    />
    {#if titleMissing}
      <span class="org-warning-copy">{@html orgWarn} Tittel påkrevd</span>
    {/if}
  </label>
  <label>
    Beskrivelse
    <span class={!isRequiredField(state, "description") ? "hidden" : ""}>*</span>
    <textarea
      class="org-input"
      rows="2"
      value={image.metadata.description || ""}
      placeholder="Beskriv hvem, hva og hvorfor til Bildebanken"
      on:input={onTextFieldInput("description")}
    />
  </label>
  <div style="position: relative">
    <label>
      Alternativ tekst
      <span class={!isRequiredField(state, "altText") ? "hidden" : ""}>*</span>
      <textarea
        class="org-input"
        rows="2"
        value={image.metadata.altText || chosenAltText || ""}
        placeholder="Beskrivelse for publikum som ikke kan se bildet"
        on:input={onTextFieldInput("altText")}
        maxlength="200"
      />
    </label>
    {#if image.metadata.altText && image.metadata.altText.length > 125}
      <div class="org-warning" style="position: relative">
        Over anbefalt lengde på alternativ tekst
      </div>
    {/if}
  </div>
  {#await useFeatureToggle("suggestAltText") then featureEnabled}
    {#if state.matches("Editing.Single") && featureEnabled}
      {#if !chosenAltText && !image.metadata.altText}
        <div class="altTextSuggestion">
          {#if state.context.gettingAltTextSuggestion}
            <div>
              <span class="org-spinner" />
              Henter KI-forslag..
            </div>
          {:else if image.metadata.altTextSuggestion}
            <label>
              KI-forslag
              <button
                aria-busy={state.context.gettingAltTextSuggestion}
                class="org-button org-button--suggestive"
                on:click={() => {
                  chosenAltText = image.metadata.altTextSuggestion || "";
                  updateField(service, "altText", chosenAltText);
                }}
              >
                {@html orgPlus}<i
                  >{image.metadata.altTextSuggestion
                    ? `"${image.metadata.altTextSuggestion}"`
                    : ""}</i
                ></button
              >
            </label>
          {/if}
        </div>
      {/if}
    {/if}
  {/await}

  <div class="row">
    <div class="fill">
      <EditImageType {service} {state} {image} />
    </div>
    <div class="fill">
      <EditVisibility {service} {image} {selectImageOnSave} {editOnlyMode} />
    </div>
  </div>

  <EditRights {service} {image} {state} {highlightedFields} />

  <!-- <PeopleInImageField {state} {service} /> -->
  <div>
    <button type="button">Avansert</button>
    <bb-expand hidden>
      <EditMediaCreatedOn {service} {image} />
    </bb-expand>
  </div>
</div>

<style>
  .altTextSuggestion {
    text-align: right;
  }
  .org-spinner {
    font-size: 0.5em;
    margin-right: 18px;
    padding-top: 3px;
  }

  .row {
    display: flex;
    gap: var(--org-small);
  }

  .row .fill {
    flex: 1;
  }

  .wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--org-medium);
    padding: var(--org-small) var(--org-medium);
    background-color: var(--color-surface-tertiary);
  }

  bb-expand {
    display: flex;
    flex-direction: column;
    gap: var(--org-small);
    padding: var(--org-xsmall);
  }

  .hidden {
    display: none;
  }

  .org-warning-copy {
    background-color: var(--org-color-warning);
    color: var(--org-color-black);
    border-radius: 3px;
    box-sizing: border-box;
    font-size: var(--org-font-small, 12px);
    font-weight: 400;
    line-height: 1;
    padding: 0.5em;
    max-width: fit-content;
  }
</style>
