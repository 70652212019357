<script lang="ts">
  import { orgPlus } from "@nrk/origo";
  import UploadButton from "components/Upload/UploadButton.svelte";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  import { getAllImagesLink, getMyImagesLink, pageState } from "state/page";
  import { getDefaultSearchParams, type SearchParams } from "state/params";
  import { getSearchHitsFromQuery } from "state/queries";
  import { createMimirSearchQuery } from "state/queries/mimir";

  import ImageGroup from "../SearchPage/ImageGroup.svelte";
  import { rootFolderIds } from "components/Folders/folders";
  import { useFeatureToggle } from "services/configCatService";
  import { getFolderContent } from "services/folders/api";

  import type { Folder as FolderType } from "services/folders/types";
  import { format } from "date-fns";
  import { getEnvironmentFromHostname } from "../../utils/fns";
  import { getBildebankenHost } from "../../config";
  import FrontPageFolder from "./FrontPageFolder.svelte";
  import { requestUserSurvey } from "services/productfruits/userSurveys";

  $: allImagesLink = getAllImagesLink($pageState);
  $: myImagesLink = getMyImagesLink($pageState);
  const recentImagesQuery = createMimirSearchQuery({ ...getDefaultSearchParams() });
  const recentImages = getSearchHitsFromQuery(recentImagesQuery);
  const myImagesParams: SearchParams = { ...getDefaultSearchParams(), owner: "current-user" };
  const myImagesQuery = createMimirSearchQuery(myImagesParams);
  const myImages = getSearchHitsFromQuery(myImagesQuery);

  let recentFolders: { folder?: FolderType; parent?: FolderType }[] = [];

  let mounted = false;
  onMount(() => {
    mounted = true;
    recentFolders = JSON.parse(localStorage.getItem("recentFolders") || "[]");
    setTimeout(() => requestUserSurvey("landing-page-plugin", {interval: {days: 5}, displayChance: .25}));
  });

  const productionRootFolderId = ["prod", "beta"].includes(
    getEnvironmentFromHostname(getBildebankenHost().hostname),
  )
    ? rootFolderIds.prod.productions
    : rootFolderIds.stage.productions;

  const todaysFolderIds = async () => {
    const todaysFolderIds = await getFolderContent(productionRootFolderId).then(async (content) => {
      const todaysFolder = content.folders.filter(
        (f) => f.name === format(new Date(), "yyyy-MM-dd"),
      )[0];
      if (todaysFolder) {
        return await getFolderContent(todaysFolder.id).then((subFolders) => {
          return subFolders.folders.map((f) => f.id);
        });
      }
    });
    return todaysFolderIds;
  };

  $: lastOpenedFolderIds = recentFolders.map((recent) => recent?.folder?.id);
  $: relevantFolderIds = lastOpenedFolderIds.slice(
    lastOpenedFolderIds.length - 1,
    lastOpenedFolderIds.length,
  );

  const validPhotographedTime = (date: Date) => {
    const now = new Date();
    const twoHoursFromNow = new Date(now.getTime() + 2 * 60 * 60 * 1000);
    return date <= twoHoursFromNow;
  };
</script>

<div class="wrapper">
  <div class="group">
    <h2><a href={allImagesLink}>Nyeste</a></h2>
    {#if $recentImagesQuery.isLoading}
      {#if mounted}
        <span transition:fade={{ delay: 1400, duration: 200 }} class="status">Henter… </span>
      {/if}
    {:else if $recentImagesQuery.isError}
      <span class="status">Kunne ikke hente.</span>
    {:else}
      <ImageGroup
        images={$recentImages
          .filter((img) => img.mediaCreatedOn && validPhotographedTime(img.mediaCreatedOn))
          .slice(0, 25)}
      ></ImageGroup>
    {/if}
  </div>
  <div class="group">
    <h2><a href={myImagesLink}>Mine bilder</a></h2>
    {#if $myImagesQuery.isLoading}
      {#if mounted}
        <span transition:fade={{ delay: 1400, duration: 200 }} class="status">Henter… </span>
      {/if}
    {:else if $myImagesQuery.isError}
      <span class="status">Kunne ikke hente.</span>
    {:else}
      <ImageGroup images={$myImages.slice(0, 7)} context={myImagesParams}>
        <svelte-fragment slot="ifSpace">
          <div class="placeholder">
            <UploadButton>
              <span>
                {@html orgPlus} Legg til bilde
              </span>
            </UploadButton>
          </div>
        </svelte-fragment>
      </ImageGroup>
    {/if}
  </div>
  {#await useFeatureToggle("showFolders") then featureEnabled}
    {#if featureEnabled}
      <div class="group">
        <h2>Produksjoner</h2>
        <div class="production-folders fade fadeEnd">
          {#if recentFolders.length > 0}
            {#each relevantFolderIds as relevantFolderId}
              <FrontPageFolder {relevantFolderId} />
            {/each}
          {/if}
          {#await todaysFolderIds() then Ids}
            {#if Ids}
              {#each Ids as id}
                {#if !relevantFolderIds.includes(id)}
                  <FrontPageFolder relevantFolderId={id} />
                {/if}
              {/each}
            {/if}
          {/await}
        </div>
      </div>
    {/if}
  {/await}
</div>

<style>
  .wrapper {
    padding: 0.5em 1.5em;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 100%;
    flex: 1;
    min-width: 0;
  }

  h2 {
    font-size: 0.95rem;
    font-weight: 600;
  }

  .placeholder {
    display: flex;
    height: calc(100% - 1px);
    width: 130px;
    flex-direction: column;
    align-items: stretch;
    align-content: center;
    justify-content: center;
    color: var(--color-text-inactive);
    border: 1px dashed var(--color-text-inactive);
    border-radius: 4px;
    font-weight: 500;
  }

  .placeholder:hover {
    cursor: pointer;
    background: var(--color-surface-secondary);
    color: var(--color-text-tertiary);
  }

  .status {
    color: var(--color-text-inactive);
  }

  .group {
    min-height: 150px;
  }

  .production-folders {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-width: none;
  }

  .fade {
    --fade-width: 75px;
    --fade-in-width: 0px;
    --fade-out-width: 0px;
    --fade-from: transparent;
    --fade-to: transparent;
    mask:
      linear-gradient(to left, black, var(--fade-from)) left / var(--fade-in-width) no-repeat,
      linear-gradient(black, black) no-repeat var(--fade-in-width) /
        calc(100% - var(--fade-in-width) - var(--fade-out-width)),
      linear-gradient(to right, black, var(--fade-to)) right / var(--fade-out-width) no-repeat;
    transition: mask 0.25s;
  }
  .fadeEnd {
    --fade-out-width: var(--fade-width);
    transition: mask 0s;
  }
</style>
