import { injectStyle } from "@nrk/origo";
import Fallback from "components/Fallback.svelte";
import Plugin from "components/Plugin/Plugin.svelte";
import Standalone from "components/Standalone.svelte";
import { getMountElement, isEmbedded } from "config";
import "defineCustomElements.js";
import { getOptions, initMessageChannel, setPluginOptions } from "services/pluginMessages";
import { initSentry, sentryCaptureException } from "services/sentry";

initSentry();

injectStyle();
const mountEl = getMountElement();

if (mountEl) {
  if (isEmbedded()) {
    initMessageChannel()
      .then(async () => {
        const options = await getOptions();
        setPluginOptions(options);
        new Plugin({ target: mountEl });
      })
      .catch((reason) => {
        sentryCaptureException(reason);
        console.warn("Plugin could not establish host communication", reason);
        new Fallback({
          target: mountEl,
          props: { message: "Kunne ikke etablere kommunikasjon med innbyggende tjeneste.", reason },
        });
      });
  } else {
    new Standalone({ target: mountEl });
  }
} else {
  console.error("Could not find element to mount BB to.");
}
