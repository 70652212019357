import type { QueryClient } from "@tanstack/svelte-query";
import pLimit from "p-limit";
import { assign, createMachine, type Sender, type StateFrom } from "xstate";

import { deleteImage } from "services/api";
import { hideMimirSearchResult } from "state/queries/mimir";
import type { MimirSearchHit } from "services/searchHit";

type DeleteImagesDialogContext = {
  selectedItems: MimirSearchHit[];
  deletedItems: string[];
  failedItems: string[];
  errorMessages: Record<string, string>;
  queryClient: QueryClient;
};

type DeleteImagesDialogEvent =
  | { type: "CONFIRM" }
  | { type: "CANCEL" }
  | { type: "DELETED"; id: string }
  | { type: "FAILED"; id: string; error: string }
  | { type: "DONE" };

function deleteImages(context: DeleteImagesDialogContext) {
  return async function (send: Sender<DeleteImagesDialogEvent>) {
    const limit = pLimit(5);
    await Promise.all(
      context.selectedItems.map((image) =>
        limit(() =>
          deleteImage(image.id)
            .then(() => {
              hideMimirSearchResult(context.queryClient, image.id);
              send({ type: "DELETED", id: image.id });
            })
            .catch((err) => {
              send({ type: "FAILED", id: image.id, error: err.message });
            }),
        ),
      ),
    );

    send({ type: "DONE" });
  };
}

export type DeleteImageState = StateFrom<typeof deleteImagesMachine>;
export const deleteImagesMachine = createMachine({
  on: {
    CANCEL: "Cancelled",
  },
  initial: "Confirming",
  states: {
    Confirming: {
      on: {
        CONFIRM: "Deleting",
      },
    },
    Deleting: {
      invoke: {
        src: deleteImages,
      },
      on: {
        DELETED: {
          actions: assign<DeleteImagesDialogContext, { type: "DELETED"; id: string }>({
            deletedItems: (context, event) => [...context.deletedItems, event.id],
          }),
        },
        FAILED: {
          actions: assign<DeleteImagesDialogContext, { type: "FAILED"; id: string; error: string }>(
            {
              failedItems: (context, event) => [...context.failedItems, event.id],
              errorMessages: (context, event) => ({
                ...context.errorMessages,
                [event.id]: event.error,
              }),
            },
          ),
        },
        DONE: [
          {
            cond: (context) => context.failedItems.length === context.selectedItems.length,
            target: "Failure",
          },
          { target: "Success" },
        ],
      },
    },
    Failure: { type: "final" },
    Success: { type: "final" },
    Cancelled: { type: "final" },
  },

  schema: {
    context: {} as DeleteImagesDialogContext,
    events: {} as DeleteImagesDialogEvent,
  },
  predictableActionArguments: true,
  preserveActionOrder: true,
});
