<script lang="ts">
  import { getBildebankenHost } from "config";
  export let mode: "table" | "tile" | "detail";
  export let state: "error" | "new";

  const errorText = "Kunne ikke vise bilde";
  const newText = "Genererer forhåndsvisning";

  const getImageLoadingUrl = () => {
    return new URL("/hourglass-icon.svg", getBildebankenHost()).toString();
  };

  const getWarningUrl = () => {
    return new URL("/warning-icon.svg", getBildebankenHost()).toString();
  };
</script>

<div class="{mode}-container" data-testid="image-placeholder-container">
  {#if state === "new"}
    <img class={mode} src={getImageLoadingUrl()} alt="Hourglass icon" />
    {#if mode === "detail"}
      <span data-testid="image-placeholder-detail-text">{newText}</span>
    {/if}
  {:else if state === "error"}
    <img class={mode} src={getWarningUrl()} alt="Warning icon" />
    {#if mode === "detail"}
      <span data-testid="image-placeholder-detail-text">{errorText}</span>
    {/if}
  {/if}
</div>

<style>
  img.tile {
    width: max(20px, calc(40px * var(--scale)));
    height: max(20px, calc(40px * var(--scale)));
  }

  img.table {
    width: min(54px, calc(14px * var(--scale)));
    height: min(54px, calc(14px * var(--scale)));
  }

  img.detail {
    width: 42px;
    height: 42px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--org-color-black);
  }

  .table-container {
    height: calc(24px * var(--scale));
    width: calc(36px * var(--scale));
  }

  .tile-container {
    height: 100%;
    gap: 0.25rem;
    flex-direction: column;
  }

  .detail-container {
    min-height: 720px;
    width: 100%;
    gap: 0.5rem;
    flex-direction: column;
    color: var(--org-color-white);
  }
</style>
