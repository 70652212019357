import { getBildebankenHost, getRelease, isEmbedded } from "../config";
import { getEnvironmentFromHostname } from "../utils/fns";
import { getAppContext } from "../services/fetch-wrapper";
import { pageState } from "../state/page";
import { get } from "svelte/store";

//Finner vi Sentry instance typen noe sted?
let Sentry;

export function initSentry() {
  if (getRelease() !== "dev") {
    import("@sentry/svelte").then((sentry) => {
      Sentry = sentry;
      Sentry.init({
        dsn: "https://0c1305aa0fa11f1b8f2b40f49858fcb5@o124059.ingest.sentry.io/4506268959440896",
        integrations: [new Sentry.BrowserTracing()],
        release: getRelease(),
        environment: getEnvironmentFromHostname(getBildebankenHost().hostname),
        beforeSend(event) {
          event.tags = {
            ...event.tags,
            isEmbedded: isEmbedded(),
            appContext: getAppContext(),
          };
          event.extra = {
            ...event.extra,
            pageState: get(pageState),
          };
          return event;
        },
      });
    });
  }
}

export function sentryCaptureException(exception) {
  if (Sentry) {
    Sentry.captureException(exception);
  } else {
    console.warn("sentryCaptureException called with no sentry instance");
  }
}
