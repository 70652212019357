import { add, compareAsc } from "date-fns";
import {
  getProductFruitsInstance,
  type ProductFruitsInstance,
} from "services/productFruitsService";
import { get, writable } from "svelte/store";

// User surveys from Product Fruits
const surveyIds = {
  search: "832f5118-05dd-452e-9df7-79fdd2356b27",
  "search-plugin": "21bf7e7d-677a-4e86-876c-212b1888451e",
  upload: "7fbbcd11-49c0-4438-baa1-226847ae9a4e",
  "upload-plugin": "6f5e425a-e1ae-4002-9a8d-44d54f0206c6",
  "landing-page": "36dc743f-9f40-40b5-a903-e7459c9ca4a8",
  "landing-page-plugin": "207d1025-4361-4e1c-8d27-2b076913685e",
};

type UserSurveyKey = keyof typeof surveyIds;

type SurveyEvent = {
  date: Date;
  reason: "last_screen" | "close_button";
};

type SerialzedSurveyEvents = { [key: string]: SurveyEvent };

const storageKey = "bildebanken-survey-events";

function getSurveyEvents() {
  try {
    const parsedEvents = JSON.parse(
      localStorage.getItem(storageKey) || "{}",
    ) as SerialzedSurveyEvents;
    const events = Object.fromEntries(
      Object.entries(parsedEvents).map(([key, value]) => [
        key,
        { ...value, date: new Date(value.date) },
      ]),
    );
    return events;
  } catch (error) {
    console.info("Failed to parse previous survey events from local storage", error);
    return {};
  }
}

const closedUserSurveys = writable(getSurveyEvents());
const activeSurveys = new Set<string>();

// Persist closed user surveys in local storage
closedUserSurveys.subscribe((events) => localStorage.setItem(storageKey, JSON.stringify(events)));

// Subscribe to survey closed events
export function trackClosedSurveys(productFruitsInstance: ProductFruitsInstance) {
  productFruitsInstance.api.surveys.listen("survey-closed", ({ surveyId, reason }) => {
    const event: SurveyEvent = { date: new Date(), reason: reason };
    const events = get(closedUserSurveys);
    events[surveyId] = event;
    closedUserSurveys.set(events);
    activeSurveys.delete(surveyId);
  });
}

function getClosedSurvey(key: UserSurveyKey): SurveyEvent | undefined {
  const id = surveyIds[key];
  return get(closedUserSurveys)[id];
}

const defaultSurveyOptions = { interval: { seconds: 0 }, displayChance: 1.0 };

// Request a user survey
export async function requestUserSurvey(
  surveyKey: UserSurveyKey,
  options?: { interval?: Duration; displayChance?: number },
) {
  const { interval, displayChance } = { ...defaultSurveyOptions, ...options };

  const now = new Date();
  const lastSurveyEvent = getClosedSurvey(surveyKey);
  const intervalElapsed =
    !lastSurveyEvent || compareAsc(add(lastSurveyEvent.date, interval), now) !== 1;
  const probabilityPassed = displayChance >= Math.random();
  const noActiveSurvey = activeSurveys.size === 0;

  if (intervalElapsed && probabilityPassed && noActiveSurvey) {
    console.debug(`Survey '${surveyKey}' started`);
    const surveyId = surveyIds[surveyKey];
    const productFruitsInstance = await getProductFruitsInstance();
    productFruitsInstance.api.surveys.startSurvey(surveyId);
    activeSurveys.add(surveyId);
  } else {
    console.debug(`Survey '${surveyKey}' skipped`, {
      lastSurvey: lastSurveyEvent,
      intervalElapsed,
      probabilityPassed,
      noActiveSurvey,
    });
  }
}
