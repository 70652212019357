<script lang="ts">
  import type { KaleidoSearchHit, MimirSearchHit, NtbSearchHit } from "services/searchHit";
  import { searchParams } from "state/page";
  import { getSearchHitsFromQuery } from "state/queries";
  import {
    createKaleidoSearchQuery,
    setKaleidoSearchHitsContext,
    setKaleidoSearchQuery,
    type SearchKaleidoQueryResult,
  } from "state/queries/kaleido";

  import {
    createNtbSearchQuery,
    type SearchNtbQueryResult,
    setNtbSearchHitsContext,
    setNtbSearchQuery,
  } from "state/queries/ntb";
  import {
    createMimirSearchQuery,
    setMimirSearchContext,
    setMimirSearchQuery,
    type MimirSearchQueryResult,
  } from "state/queries/mimir";
  import { writable } from "svelte/store";
  import { origoNotification } from "services/notifications";

  // Bildebanken search query
  $: mimirQuery = createMimirSearchQuery($searchParams);
  const mergedHits = writable([] as MimirSearchHit[]);
  const mergedQuery = writable<MimirSearchQueryResult>();
  $: mimirSearchHits = getSearchHitsFromQuery(mimirQuery);

  // Loading many search hits causes blocking rendering (because there are so many changes),
  // which means some changes that should be immediately rendered (e.g. input elements for filters),
  // aren't responsive enough. The below allows those changes to render before the search hit
  // rendering blocks everything else.
  $: setTimeout(() => mergedHits.set($mimirSearchHits), 0);
  $: mergedQuery.set($mimirQuery);
  setMimirSearchContext(mergedHits);
  setMimirSearchQuery(mergedQuery);

  // Kaleido search query
  $: kaleidoQuery = createKaleidoSearchQuery($searchParams);
  $: if ($kaleidoQuery.error) {
    origoNotification("Klarte ikke søke i Kaleido. Er du på NRK-nettverket eller VPN?", "warning");
  }
  const mergedHitsKaleido = writable([] as KaleidoSearchHit[]);
  const mergedKaleidoQuery = writable<SearchKaleidoQueryResult>();
  $: searchHitsKaleido = getSearchHitsFromQuery(kaleidoQuery);
  $: mergedKaleidoQuery.set($kaleidoQuery);
  $: setTimeout(() => mergedHitsKaleido.set($searchHitsKaleido), 0);
  setKaleidoSearchHitsContext(mergedHitsKaleido);
  setKaleidoSearchQuery(mergedKaleidoQuery);

  // NTB search query
  $: ntbQuery = createNtbSearchQuery($searchParams);
  const mergedHitsNtb = writable([] as NtbSearchHit[]);
  const mergedNtbQuery = writable<SearchNtbQueryResult>();
  $: searchHitsNtb = getSearchHitsFromQuery(ntbQuery);
  $: mergedNtbQuery.set($ntbQuery);
  $: setTimeout(() => mergedHitsNtb.set($searchHitsNtb), 0);
  setNtbSearchHitsContext(mergedHitsNtb);
  setNtbSearchQuery(mergedNtbQuery);
</script>

<slot />
