import { getAccount } from "auth/auth";
import { first, last } from "lodash-es";
import { productFruits } from "product-fruits";
import { get, writable } from "svelte/store";
import { trackClosedSurveys } from "./productfruits/userSurveys";

const PRODUCT_FRUITS_WORKSPACE = "AK4c3bQ1LkVQgfWK";

export type ProductFruitsInstance = {
  api: ProductFruitsApi;
  identifyUser: () => void;
  pageChanged: () => void;
  services: {
    fire?: () => void;
    isLoaded?: true;
    setLoggerLevel: (level: string) => void;
    resetLoggerLevel: () => void;
  };
};

type ProductFruitsApi = {
  analytics: {
    forwardEvent: (callback: (event: string, data: unknown) => void) => void;
  };
  announcements: {
    getAnnouncementsByChannel: (channelId: string) => unknown;
    showAnnouncement: (id: string) => void;
    showHistory: (channelId: string) => void;
    attachNewsWidgetToElement: (htmlElementInstance: HTMLElement) => void;
    listen: (
      event: "banner-spacing" | "newsfeed-unread-count-changed",
      callback: (event: unknown) => void,
    ) => void;
  };
  announcementsV2: {
    attachNewsWidgetToElement: (htmlElementInstance) => void;
    listen: (event: string, callback: (event: unknown) => void) => void;
  };
  button: {
    show: () => void;
    open: () => void;
    hide: () => void;
    close: () => void;
    isOpened: () => boolean;
    listen: (event: "closed" | "opened", callback: (event: unknown) => void) => void;
  };
  checklists: {
    markItemAsDone: (internalId: string, expand?: boolean) => void;
    injectToElement: (
      checklistId: string,
      element: HTMLElement,
      options: { checkEligibility: boolean },
    ) => void;
    getChecklists: () => {
      id: string;
      name: string;
      items: unknown;
      state: "not_touched" | "finished" | "in_progress";
    }[];
    listen: (
      event: "item-launched" | "item-completed" | "dismissed",
      callback: (event: unknown) => void,
    ) => void;
  };
  events: {
    track: (event: string) => void;
  };
  feedback: {
    showModal: (
      position?: { top?: string; left?: string; bottom?: string; right?: string },
      onClose?: () => void,
    ) => void;
  };
  hints: { updatePositions: () => void };
  surveys: {
    startSurvey: (surveyId: string) => void;
    listen: (
      event: "survey-closed",
      callback?: (event: {
        surveyId: string;
        reason: "last_screen" | "close_button";
        answers: unknown[];
      }) => void,
    ) => void;
  };
  tours: {
    getTours: () => {
      id: string;
      name: string;
      isRunning: boolean;
      currentCard: { id: string; name: string } | null;
      userState: "not_finished" | "finished" | "skipped";
      rulesValid: () => void;
    }[];
    tryStartTour: (id: string) => void;
    markAs: (id: string, state: "finished" | "skipped", kill?: boolean) => void;
    advanceToNextStep: (id: string) => void;
    listen: (
      event: "tour-finished" | "tour-skipped" | "tour-advanced" | "tour-card-completed",
      callback: (event: unknown) => void,
    ) => void;
  };
};

export const productFruitsInstance = writable<ProductFruitsInstance | undefined>(undefined);
export const hoveredAlternative = writable<string | undefined>(undefined);
export const selectedAlternative = writable<string | undefined>(undefined);

export const initializeProductFruits = () => {
  const account = getAccount();
  const username = account.email?.toLowerCase() || account.username?.toLowerCase();
  if (username) {
    const userInfo = {
      username,
      email: account.email,
      firstname: account.givenName || first(account.name?.split(" ")),
      lastname: account.familyName || last(account.name?.split(" ")),
      props: { groups: account.groups || [] },
    };

    productFruits.init(PRODUCT_FRUITS_WORKSPACE, "no", userInfo);
    window.addEventListener("productfruits_ready", function () {
      if ("productFruits" in window) {
        const instance = window.productFruits as ProductFruitsInstance;
        productFruitsInstance.set(instance);
        trackClosedSurveys(instance);
      }
      const observer = new MutationObserver((mutationList) => {
        if (mutationList.filter((mutation) => mutation.type === "childList").length > 0) {
          observeSurvey();
        }
      });
      const shadowRoot = first(document.getElementsByClassName("productfruits--container"))
        ?.shadowRoot;
      const containerRoot = first(shadowRoot?.childNodes);
      containerRoot &&
        observer.observe(containerRoot, { attributes: true, childList: true, subtree: true });
    });
  }
};

export async function getProductFruitsInstance(): Promise<ProductFruitsInstance> {
  const instance = get(productFruitsInstance);
  if (instance !== undefined) {
    return instance;
  }

  return new Promise((resolve) => {
    const unsubscribe = productFruitsInstance.subscribe((value) => {
      if (value !== undefined) {
        resolve(value);
        unsubscribe();
      }
    });
  });
}

const observeSurvey = () => {
  const shadowRoot = first(document.getElementsByClassName("productfruits--container"))?.shadowRoot;

  window.setTimeout(() => {
    const surveyContainer = shadowRoot?.querySelector(".pfruits-survey-container");
    const scoreElements = surveyContainer?.querySelector(".score-scale");
    const emoElements = surveyContainer?.querySelector(".emos");
    const choiceElements = surveyContainer?.querySelector(".choices");
    const alternatives = Array.from(
      scoreElements?.children || emoElements?.children || choiceElements?.children || [],
    );
    alternatives?.forEach((button) => {
      button.addEventListener("mouseenter", (event) => {
        const value = (event.target as HTMLElement).innerText.trim();
        hoveredAlternative.set(value);
      });
      button.addEventListener("mouseleave", () => {
        hoveredAlternative.set(undefined);
      });
      button.addEventListener("click", (event) => {
        const value = (event.target as HTMLElement).innerText.trim();
        selectedAlternative.set(value);
      });
    });
  }, 1500);
};
