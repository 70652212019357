import { type Image } from "bildebanken-model";
import { glassPaneState } from "components/Plugin/GlassPane";
import { getMetadataItem, sendImageToKaleido } from "services/api";
import { selectImageCallback } from "services/pluginMessages";
import type { SearchHit } from "services/searchHit";
import { pageState, showDetails } from "../../state/page";
import {
  imageIsPublished,
  mapBildebankenImageToSelectedImage,
  type PublishedImage,
} from "./imageTypes";
import { appendKaleidoDerivateUrlToImage } from "./services/derivate";
import { productFruitsInstance } from "services/productFruitsService";
import { get } from "svelte/store";
import { copyNtbImageToMimir } from "services/ntb";

export async function publishAndSelectNtb(ntbId: string) {
  try {
    glassPaneState.set({ state: "VISIBLE", message: "Henter bilde fra NTB..." });

    const { id, height, width } = await copyNtbImageToMimir(ntbId);
    let image = await getMetadataItem(id);

    if (!image.metadata.publicId) {
      image = await sendImageToKaleido(id);
    }
    const selectedImage = mapBildebankenImageToSelectedImage(image as PublishedImage);
    // Set width/height using ntb data in case it is (not yet) ready from Mimir
    if (
      selectedImage.technicalMetadata?.height === undefined ||
      selectedImage.technicalMetadata?.width === undefined
    ) {
      selectedImage.technicalMetadata.height = height;
      selectedImage.technicalMetadata.width = width;
    }
    await appendKaleidoDerivateUrlToImage(selectedImage);
    selectImageCallback(selectedImage);

    pageState.subscribe(() => {
      glassPaneState.set({ state: "HIDDEN" });
    });
  } catch (err) {
    glassPaneState.set({
      state: "SHOW_ERROR",
      message: "Noe gikk galt ved henting fra NTB",
      details: err.message,
    });
  }
}

export async function publishAndSelectKaleido(kaleidoId: string) {
  const image = await getMetadataItem(kaleidoId, "Kaleido");

  if (!imageIsPublished(image)) {
    // we shouldn't ever get here, but if we do we get a helpful error message
    glassPaneState.set({
      state: "SHOW_ERROR",
      message: "Kunne ikke velge bildet",
      details: "Bildet er ikke publisert til Kaleido",
    });

    return;
  }

  const selectedImage = mapBildebankenImageToSelectedImage(image, "Kaleido");
  await appendKaleidoDerivateUrlToImage(selectedImage);
  selectImageCallback(selectedImage);
}

export async function publishAndSelect(imageOrSearchHit: SearchHit | Image): Promise<void> {
  get(productFruitsInstance)?.api.events.track("select-image-requested");
  glassPaneState.set({ state: "VISIBLE", message: "Henter bilde ..." });
  pageState.subscribe(() => {
    glassPaneState.set({ state: "HIDDEN" });
  });

  let image: Image;
  try {
    if (imageOrSearchHit.metadata.publicId) {
      image = await getMetadataItem(imageOrSearchHit.id);
    } else {
      image = await sendImageToKaleido(imageOrSearchHit.id);
    }

    if (!imageIsPublished(image)) {
      // we shouldn't ever get here, but if we do we get a helpful error message
      glassPaneState.set({
        state: "SHOW_ERROR",
        message: "Kunne ikke velge bildet",
        details: "Bildet er ikke publisert til Kaleido",
      });

      return;
    }

    const selectedImage = mapBildebankenImageToSelectedImage(image);
    //Skulle denne if'en vært inni mapBildebankenImageToSelectedImage?
    if (
      !isSearchHit(imageOrSearchHit) &&
      imageOrSearchHit.metadata.imageDimensions?.height &&
      imageOrSearchHit.metadata.imageDimensions?.width
    ) {
      selectedImage.technicalMetadata.height = imageOrSearchHit.metadata.imageDimensions.height;
      selectedImage.technicalMetadata.width = imageOrSearchHit.metadata.imageDimensions.width;
    }
    await appendKaleidoDerivateUrlToImage(selectedImage);

    selectImageCallback(selectedImage);

    showDetails(imageOrSearchHit.id);
  } catch (err) {
    glassPaneState.set({
      state: "SHOW_ERROR",
      message: "Noe gikk galt",
      details: err.message,
    });
  }
}

function isSearchHit(image): image is SearchHit {
  return image.state;
}
