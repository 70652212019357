<script lang="ts" context="module">
  import { orgRightsNrk, orgRightsOther, orgRightsUnknown } from "@nrk/origo";
  import type { RightsMarker } from "bildebanken-model";

  type Option = {
    value: RightsMarker;
    header: string;
    info: string;
    icon: string;
    css: string;
  };

  const allOptions: Readonly<Option[]> = [
    {
      value: "Free",
      header: "NRK",
      info: "Fri gjenbruk i NRK",
      icon: orgRightsNrk,
      css: "nrk",
    },
    {
      value: "Restricted",
      header: "Vilkår for gjenbruk",
      info: "Les vilkår før bruk",
      icon: orgRightsOther,
      css: "restricted",
    },
    {
      value: "Unknown",
      header: "Ukjent",
      info: "Ingen informasjon",
      icon: orgRightsUnknown,
      css: "unknown",
    },
  ];
</script>

<script lang="ts">
  import {
    AGENTS,
    calculateCreditLine,
    calculateRightsMarker,
    type Image,
    type Rights,
  } from "bildebanken-model";
  import { wait } from "../../utils/fns";
  import RightsIndicator from "../Common/RightsIndicator.svelte";
  import type { EditImageActor, EditImageState } from "./editImageMachine";
  import { isRequiredField, updateField } from "./editImageMachine";
  import EditPhotographer from "./EditPhotographer.svelte";
  import { currentUserAuthority } from "../../services/authority";
  import { orgWarn } from "@nrk/origo";
  export let service: EditImageActor;
  export let state: EditImageState;
  export let image: Image;
  export let highlightedFields: string[] | undefined;

  const required = isRequiredField(state, "rights");
  const options = required ? allOptions.filter((opt) => opt.value !== "Unknown") : allOptions;

  let dirty = false;
  let selectedOption = calculateRightsMarker(image.metadata.rights);
  let label = `Vilkår og kreditering ${required ? " *" : ""}`;

  const contactWithAuthority = !!currentUserAuthority;

  $: creditLine = calculateCreditLine(image.metadata);

  function onChangeRightsHolder(this: HTMLInputElement) {
    const title = this.value;
    let newRights: Rights;

    if (title) {
      let resId: string | undefined;
      if (title === "NRK") resId = AGENTS.NRK;

      newRights = {
        ...image.metadata.rights,
        rightsHolder: { contact: { title, resId } },
      };
    } else {
      newRights = {
        ...image.metadata.rights,
        rightsHolder: undefined,
      };
    }

    selectedOption = calculateRightsMarker(newRights);
    updateField(service, "rights", newRights);
  }

  function onChangeRightsNote(this: HTMLInputElement) {
    const newRights = {
      ...image.metadata.rights,
      note: this.value || undefined,
    };

    selectedOption = calculateRightsMarker(newRights);
    updateField(service, "rights", newRights);
    updateField(service, "usageTerms", this.value);
  }

  function onChangeCredit(this: HTMLInputElement) {
    const newRights = {
      ...image.metadata.rights,
      credit: this.value || undefined,
    };
    selectedOption = calculateRightsMarker(newRights);
    updateField(service, "rights", newRights);
  }

  function onChangeRightsMarker(this: HTMLInputElement & { value: RightsMarker }) {
    setRightsMarker(this.value);
  }

  function setRightsMarker(value?: RightsMarker) {
    let newRights: Rights | null = null;
    switch (value) {
      case "Free":
        newRights = {
          credit: image.metadata.rights?.credit,
          rightsHolder: { contact: { resId: AGENTS.NRK, title: "NRK" } },
        };
        updateField(service, "usageTerms", undefined);

        break;
      case "Restricted": {
        newRights = {
          ...image.metadata.rights,
          rightsHolder: { contact: { title: "" } },
        };
        break;
      }
      case "Unknown":
        if (image.metadata.rights?.credit) {
          newRights = { credit: image.metadata.rights?.credit };
        }
        updateField(service, "usageTerms", undefined);
        break;
      default:
        break;
    }

    updateField(service, "rights", newRights);
  }

  $: invalid = required && selectedOption === "Unknown";

  async function onFocusOut(this: HTMLElement) {
    // only run this when we leave the component
    await wait(0); // seems to still be focus at the moment of focusout
    if (this.querySelectorAll(":focus").length > 0) return;

    // this is better but doesn't work with jsdom for tests https://github.com/jsdom/jsdom/issues/3055
    //if (this.matches(":focus-within")) return;

    dirty = true;
    selectedOption = calculateRightsMarker(image.metadata.rights);

    if (selectedOption === "Unknown") {
      // Necessary to clean up empty strings from rightsholder and note.
      setRightsMarker("Unknown");
    }
  }

  function highlightedFieldsContainCredit() {
    return highlightedFields && highlightedFields.some((rf) => rf === "credit");
  }
</script>

<div>
  <button type="button" class="expand-toggle" aria-label={label}>
    <span>{label}</span>
    <span>
      <RightsIndicator rightsMarker={selectedOption} />
      <em>{creditLine || "Ukjent"}</em>
    </span>
  </button>
  <bb-expand hidden={!required && !highlightedFieldsContainCredit()}>
    <!-- svelte-ignore a11y-role-supports-aria-props -->
    <fieldset
      class="org-fieldset"
      aria-invalid={dirty && invalid}
      data-testid="edit-rights"
      on:focusout={onFocusOut}
    >
      <legend class="org-sr">Vilkår og kreditering</legend>
      <div class="select-rights">
        {#each options as option (option.value)}
          <div>
            <label class:selected={option.value === selectedOption} class={option.css}>
              <span class="icon">{@html option.icon}</span>
              <span class="text">
                <span class="header">{option.header}</span>
                <span class="info">{option.info}</span>
              </span>
              <input
                class="org-sr"
                name="rights"
                type="radio"
                value={option.value}
                bind:group={selectedOption}
                on:change={onChangeRightsMarker}
              />
            </label>
          </div>
        {/each}
      </div>

      <div class="text-fields">
        <div class="credit-row">
          <div class="photographer">
            <EditPhotographer {image} {service} />
          </div>
          <label>
            Rettighetshaver
            <input
              class="org-input"
              type="text"
              size="14"
              value={image.metadata.rights?.rightsHolder?.contact?.title ?? ""}
              on:input={onChangeRightsHolder}
              placeholder="NTB, Operaen…"
            />
          </label>
          <label>
            Overstyr kreditering
            <input
              class="org-input"
              type="text"
              size="14"
              on:input={onChangeCredit}
              on:blur={onChangeCredit}
              placeholder={"Privat f.eks."}
              value={image.metadata.rights?.credit ?? ""}
              data-testid="credit-override-input"
            />
          </label>
        </div>
        {#if !contactWithAuthority}
          <span class="org-warning-copy"
            >{@html orgWarn} Klarer ikke hente fra autoritetsregisteret. Er du på NRK-nettverket eller
            VPN?</span
          >
        {/if}
        <label>
          Vilkår
          <textarea
            class="org-input"
            rows="2"
            value={image.metadata.rights?.note ?? ""}
            on:input={onChangeRightsNote}
            placeholder="Eksempler: Kun til omtale av et tema, sperrefrist med dato."
          />
        </label>
        <div></div>
      </div>

      <!--  Validation state controlled by aria-invalid on fieldset -->
      <span class="org-warning" data-testid="required-rights-warning">
        Rettigheter må settes (minst én av rettighetseier eller notat)
      </span>
    </fieldset>
  </bb-expand>
</div>

<style>
  .expand-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .expand-toggle span:first-child {
    flex: 1;
  }

  .credit-row {
    display: flex;
    gap: var(--org-small);
  }

  .photographer {
    flex: 1;
  }

  .select-rights {
    display: flex;
    flex-wrap: wrap;
    gap: var(--org-small);
    margin: var(--org-small) 0;
    padding: var(--org-xsmall);
  }

  legend {
    float: left;
    width: 100%;
  }

  fieldset.org-fieldset {
    border-radius: 2px;
    padding: 5px 5px 10px 10px;
  }

  /* Augment origo styles for error state */
  fieldset.org-fieldset[aria-invalid]:not([aria-invalid="false"]) {
    border-radius: 2px 2px 2px 0;
    box-shadow: 0 0 0 2px var(--org-color-warning);
    margin-bottom: 20px;
  }

  .select-rights label {
    flex: 1;
    min-width: 180px;
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: var(--org-small);
    border-radius: var(--bb-standard-border-radius);
    overflow: hidden;
    padding: var(--org-small);
    border: 1px var(--org-color-shade-2) dashed;
    cursor: pointer;
    transition: 100ms;
    will-change: transform;
  }

  .select-rights label.selected {
    border-color: transparent;
    background-color: var(--org-color-shade-1);
  }

  .select-rights label.selected::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    height: 3px;
    width: 100%;
    background-color: var(--org-color-primary);
  }

  .select-rights label:hover {
    transform: translateY(-1px);
    transition: transform 400ms;
    background-color: var(--org-color-shade-2);
  }

  .select-rights label:has(:focus) {
    box-shadow: var(--org-shadow-focus);
  }

  .icon {
    display: flex;
    align-items: center;
    padding: var(--org-xsmall);
    color: var(--org-color-white);
    border-radius: 6px;
  }

  .org-warning-copy {
    background-color: var(--org-color-warning);
    color: var(--org-color-black);
    border-radius: 3px;
    box-sizing: border-box;
    font-size: var(--org-font-small, 12px);
    font-weight: 400;
    line-height: 1;
    padding: 0.5em;
    max-width: fit-content;
  }

  .unknown .icon {
    background-color: var(--nrk-color-cool-orange-400);
  }

  .nrk .icon {
    background-color: var(--nrk-color-warm-green-500);
  }

  .restricted .icon {
    background-color: var(--nrk-color-red-400);
  }

  .text span {
    display: block;
  }

  .header {
    font-weight: bold;
    font-size: var(--org-font-2medium);
  }
  .info {
    font-weight: normal;
  }

  .text-fields {
    display: flex;
    flex-wrap: wrap;
    gap: var(--org-small);
  }

  .text-fields > * {
    flex-basis: 100%;
  }

  .half-width {
    min-width: 200px;
    flex: 1;
  }
</style>
